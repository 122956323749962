import { Application } from '@hotwired/stimulus';

const application = Application.start();

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV === 'development';
window.Stimulus = application;

// Custom action option. Only fires after the user has typed more than two characters.
application.registerActionOption('afterTwoCharacters', ({ event }) => event.target.value !== undefined && (event.target.value.length === 0 || event.target.value.length > 2));

// eslint-disable-next-line import/prefer-default-export
export { application };
