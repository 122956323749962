import { Controller } from '@hotwired/stimulus';
import getCSSVariable from '../utils/get_css_variable';

export default class extends Controller {
  static targets = ['map', 'listing'];

  static values = {
    activeColorVar: { type: String, default: 'annotation-color' },
    inactiveColorVar: { type: String, default: 'annotation-color-inactive' },
  };

  async connect() {
    this.isConnected = false;

    this.observeMapHeight();
    this.observeViewport();
    this.observeListingIntersections();
    this.annotationColor = getCSSVariable(this.element, this.activeColorVarValue);

    await this.initializeMap();

    this.listings = [];
    this.listingTargets.forEach(this.addAnnotation.bind(this));

    // Helpful for grabbing test coordinates
    this.map.addEventListener('single-tap', (e) => {
      const test = this.map.convertPointOnPageToCoordinate(e.pointOnPage);
    });

    this.updateListings();
    this.isConnected = true;
  }

  get zoomOnSelect() {
    return !!this.isMobile;
  }

  get mapPadding() {
    if (this.isMobile) {
      return {
        top: 50, right: 50, bottom: 50, left: 50,
      };
    }
    return {
      top: 100, right: 100, bottom: 100, left: 100,
    };
  }

  handleViewportChange(e) {
    this.isMobile = e.matches;
  }

  observeMapHeight() {
    this.mapHeight = this.mapTarget.offsetHeight;
    this.imageResizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentBoxSize) {
          this.mapHeight = entry.contentBoxSize[0].blockSize;
          this.element.style.setProperty('--map-height', `${this.mapHeight}px`);
        }
      });
    });
    this.imageResizeObserver.observe(this.mapTarget);
  }

  observeViewport() {
    this.mq = window.matchMedia('(max-width: 45rem)');
    this.mq.addEventListener('change', this.handleViewportChange.bind(this));
    this.isMobile = this.mq.matches;
  }

  observeListingIntersections() {
    this.listingObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const annotation = this.map.annotations.find(
          (a) => a.data.elementId === entry.target.id,
        );
        if (entry.isIntersecting) {
          annotation.enabled = false;
          annotation.selected = true;
        } else {
          annotation.enabled = true;
          annotation.selected = false;
        }
      });
    }, {
      rootMargin: '-50% 0px -50% 0px',
    });
  }

  async initializeMap() {
    await window.mapkitInitialized;

    if (!this.map) {
      this.map = new window.mapkit.Map(this.mapTarget, {
        pointOfInterestFilter: window.mapkit.PointOfInterestFilter.including([
          window.mapkit.PointOfInterestCategory.ATM,
          window.mapkit.PointOfInterestCategory.Park,
          window.mapkit.PointOfInterestCategory.PublicTransport,
          window.mapkit.PointOfInterestCategory.Parking,
          window.mapkit.PointOfInterestCategory.School,
        ]),
        showsMapTypeControl: false,
        showsZoomControl: false,
        showsScale: false,
      });
    }
  }

  addAnnotation(element) {
    const coords = new window.mapkit.Coordinate(
      parseFloat(element.dataset.lat),
      parseFloat(element.dataset.lng),
    );
    const annotation = new window.mapkit.MarkerAnnotation(coords, {
      color: this.annotationColor || null,
      data: {
        elementId: element.id,
      },
    });

    annotation.addEventListener('select', (e) => {
      window.history.replaceState({}, document.title, `#${e.target.data.elementId}`);
      if (e.target.enabled) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        e.target.enabled = true;
      }
      this.map.setCenterAnimated(e.target.coordinate);
    });

    this.listingObserver.observe(element);
    this.listings.push(annotation);
  }

  updateListings() {
    this.map.showItems(this.listings, {
      animate: true,
      padding: new window.mapkit.Padding(this.mapPadding),
    });
  }
}
