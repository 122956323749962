/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { Turbo } from '@hotwired/turbo-rails';
import { application } from '@controllers/application';

import AdController from '@controllers/ad_controller';
import CarouselController from '@controllers/carousel_controller';
import DisplayAdController from '@controllers/display_ad_controller';
import NativeAdController from '@controllers/native_ad_controller';
import ImageController from '@controllers/image_controller';
import MapController from '@controllers/map_controller';
import ModalController from '@controllers/modal_controller';
import ScrollController from '@controllers/scroll_controller';
import ShareButtonController from '@controllers/share_button_controller';
import SiteHeaderController from '@controllers/site_header_controller';
import SlideshowController from '@controllers/slideshow_controller';
import StickyController from '@controllers/sticky_controller';
import ToggleController from '@controllers/toggle_controller';
import ToggleGroupController from '@controllers/toggle_group_controller';

application.register('ad', AdController);
application.register('carousel', CarouselController);
application.register('display-ad', DisplayAdController);
application.register('image', ImageController);
application.register('map', MapController);
application.register('modal', ModalController);
application.register('native-ad', NativeAdController);
application.register('scroll', ScrollController);
application.register('share-button', ShareButtonController);
application.register('site-header', SiteHeaderController);
application.register('slideshow', SlideshowController);
application.register('sticky', StickyController);
application.register('toggle', ToggleController);
application.register('toggle-group', ToggleGroupController);

Turbo.session.drive = false;

/**
 * Prevent CSS transitions from being accidentally triggered during
 * page load. See application.scss
 */
document.addEventListener('DOMContentLoaded', () => {
  document.body.classList.remove('preload');
});

window.addEventListener('pagehide', () => {
  document.body.classList.add('preload');
});
