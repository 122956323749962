import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { isOpen: { type: Boolean, default: false } };

  static targets = ['toggleable'];

  static classes = ['visible'];

  open() {
    this.isOpenValue = true;
  }

  close() {
    this.isOpenValue = false;
  }

  toggle() {
    this.isOpenValue = !this.isOpenValue;
  }

  isOpenValueChanged(value, oldValue) {
    if (oldValue === undefined) return;

    if (value) {
      this.toggleableTarget.classList.add(...this.defaultVisibleClasses);
      this.dispatch('show');
    } else {
      this.toggleableTarget.classList.remove(...this.defaultVisibleClasses);
      this.dispatch('hide');
    }
  }

  get defaultVisibleClasses() {
    return this.hasVisibleClass ? this.visibleClasses : ['js-is-visible'];
  }
}
