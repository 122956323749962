import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['stage', 'tabs', 'prevButton', 'nextButton'];

  initialize() {
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.pageWidth = null;
    this.currentPage = 0;
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect) {
          if (this.pageWidth === entry.contentRect.width) return;

          this.pageWidth = entry.contentRect.width;
          this.buildTabs();

          // The number of items per page may have changed, so we need to recalculate
          // the current page based on the current scroll position.
          this.navigateToPage(Math.floor(this.stageTarget.scrollLeft / this.pageWidth));
        }
      });
    });
  }

  connect() {
    this.resizeObserver.observe(this.element);
  }

  get numPages() {
    return Math.ceil(this.stageTarget.scrollWidth / this.pageWidth);
  }

  buildTabs() {
    if (!this.hasTabsTarget) return;

    this.tabsTarget.innerHTML = '';
    for (let i = 0; i < this.numPages; i++) {
      const button = document.createElement('button');
      button.dataset.page = i + 1;
      // Replace with events
      button.addEventListener('click', () => this.navigateToPage(i));
      this.tabsTarget.appendChild(button);
    }
  }

  updateActiveNavigation() {
    if (!this.hasTabsTarget) return;

    if (this.currentPage === 0) {
      this.prevButtonTarget.disabled = true;
      this.nextButtonTarget.disabled = false;
    } else if (this.currentPage === this.numPages - 1) {
      this.prevButtonTarget.disabled = false;
      this.nextButtonTarget.disabled = true;
    } else {
      this.prevButtonTarget.disabled = false;
      this.nextButtonTarget.disabled = false;
    }

    Array.from(this.tabsTarget.children).forEach((item) => item.classList.remove('active'));
    this.tabsTarget.children[this.currentPage].classList.add('active');
  }

  navigateToPage(page) {
    if (page < 0 || page >= this.numPages) return;

    this.currentPage = page;
    this.updateActiveNavigation();
    this.stageTarget.scrollTo({ left: this.pageWidth * page, top: 0, behavior: 'smooth' });
  }

  prevPage() {
    this.navigateToPage(this.currentPage - 1);
  }

  nextPage() {
    this.navigateToPage(this.currentPage + 1);
  }
}
